import React, { useContext } from 'react';

import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { AuthContext } from '../context/AuthContext';
import DashboardTable from './dashboard/DashboardTable';
import MarketPerformanceBarChart from './charts/MarketPerformanceBarChart';
import StatCard from './charts/StatCard';
import DashboardPieChart from './charts/DashboardPieChart';

import DashboardHelper from '../internals/dashboardHelper';
import TimeHelper from '../internals/timeHelper';

const Dashboard = () => {
  const { user } = useContext(AuthContext);
  const [assets, setAssets] = React.useState([]);
  const [loans, setLoans] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [filter, setFilter] = React.useState(0);

  React.useEffect(() => {
    if (user?.assets) {
      setAssets(user.assets);
      setLoading(false);
    }
  }, [user]); // Runs when user updates

  React.useEffect(() => {
    if (user?.loans) {
      setLoans(user.loans);
      setLoading(false);
    }
  }, [user]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  if (loading === true) {
    return (<div></div>);
  }

  if (assets.length === 0) {
    return (
      <Box className='Dashboard manager-box'>
        <div className='flex-space'>
          <Typography className='title' variant="h5">
            Welcome to your Dashboard! 🎉
          </Typography>
          <Typography className='title' variant="h5">
            Here is where you will find visualizations of valuable market metrics for your assets. Click here to get started adding your first asset TODO: link directly to add asset modal
          </Typography>
        </div>
      </Box>
    );
  }

  //TODO: figure out a way to pass in graphs and necessary calculations only

  const assetValuationOverTime = DashboardHelper.assetValuationOverTime(assets, TimeHelper.generateMonthlyTimeSeries());
  const fairMarketValueOverTime = DashboardHelper.fairMarketValueOverTime(assets, TimeHelper.generateMonthlyTimeSeries());
  const debtOverTime = DashboardHelper.debtOverTime(loans, TimeHelper.generateMonthlyTimeSeries())
  const projectedRoiOverTime = DashboardHelper.projectedRoiOverTime(assets, TimeHelper.generateMonthlyTimeSeries());

  const marketPerformanceData = { bookValues: assetValuationOverTime.slice(-6), estimatedMarketValues: fairMarketValueOverTime.slice(-6) };

  const pieChartData = DashboardHelper.percentOfTotalAssetValueByManufacturer(assets);

  const statCardData = [
    {
      title: 'Total Asset Value',
      data: assetValuationOverTime,
      format: 'dollars',
    },
    {
      title: 'Total Debt',
      data: debtOverTime,
      format: 'dollars'
    },
    {
      title: 'Fair Market Value',
      data: fairMarketValueOverTime,
      format: 'dollars',
    },
    {
      title: 'Total Projected ROI',
      data: projectedRoiOverTime,
      format: 'percentage',
    },
  ];

  return (
    <Box className='Dashboard manager-box'>
      <div className='flex-space'>
        <Typography className='title' variant="h5">
          Dashboard
        </Typography>
        <div>
          <Typography variant="h7">
            Filter by:
          </Typography>
          <Select
            labelId="asset-selector-label"
            value={filter}
            onChange={handleFilterChange}
            sx={{ height: '30px', marginLeft: '10px' }}
          >
            <MenuItem value={0}>- All Assets -</MenuItem>
            <MenuItem value={1}>Asset 1</MenuItem>
            <MenuItem value={2}>Asset 2</MenuItem>
          </Select>
        </div>
      </div>

      <Grid
        container
        spacing={2}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(2) }}
      >
        {statCardData.map((card, index) => (
          <Grid key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
            <StatCard {...card} />
          </Grid>
        ))}
        <Grid size={{ sm: 12, md: 6 }}>
          <DashboardPieChart data={pieChartData} />
        </Grid>
        <Grid size={{ sm: 12, md: 6 }}>
          <MarketPerformanceBarChart {...marketPerformanceData} />
        </Grid>
      </Grid>
      <Typography className='title' variant="h6">
        Asset Report
      </Typography>
      <Grid container spacing={2} columns={12}>
        <Grid size={{ sm: 12 }}>
          <DashboardTable />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Dashboard;