import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { states } from '../../states';
import { AuthContext } from '../../context/AuthContext';
import { createAssetService } from '../../services/assetService';

const AddAsset = ({ open, handleClose }) => {
  const { addAsset } = useContext(AuthContext);

  const [type, setType] = useState('');
  const [bookValue, setBookValue] = useState('');
  const [salvageValue, setSalvageValue] = useState('');
  const [manufacturer, setManufacturer] = useState('');
  const [model, setModel] = useState('');
  const [modelYear, setModelYear] = useState('');
  const [usage, setUsage] = useState('');
  const [condition, setCondition] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [depreciationMethod, setDepreciationMethod] = useState('');
  const [usefulLife, setUsefulLife] = useState('');
  const [depreciationRate, setDepreciationRate] = useState('');
  const [totalExpectedUnitsOfProduction, setTotalExpectedUnitsOfProduction] = useState('');
  const [unitsProducedInYear, setUnitsProducedInYear] = useState('');
  const [showDepreciation, setShowDepreciation] = useState(false);
  const [showUsefulLife, setShowUsefulLife] = useState(false);
  const [showDepreciationRate, setShowDepreciationRate] = useState(false);
  const [showTotalExpectedUnitsOfProduction, setShowTotalExpectedUnitsOfProduction] = useState(false);
  const [showUnitsProducedInYear, setShowUnitsProducedInYear] = useState(false);
  const user = JSON.parse(sessionStorage.getItem('currentUser'));

  const validateInputs = () => {
    let isValid = true;

    if (!type) isValid = false;
    if (!bookValue || isNaN(bookValue) || parseFloat(bookValue) <= 0) isValid = false;
    if (!salvageValue || isNaN(salvageValue) || parseFloat(salvageValue) < 0) isValid = false;
    if (!manufacturer) isValid = false;
    if (!model) isValid = false;
    if (!modelYear || isNaN(modelYear) || parseInt(modelYear, 10) <= 0) isValid = false;
    if (!usage || isNaN(usage) || parseInt(usage, 10) < 0) isValid = false;
    if (!condition) isValid = false;
    if (!country) isValid = false;
    if (country === 'US' && !state) isValid = false;

    return isValid;
  };
  const handleAddAsset = () => {
    const newAsset = {
      name: `${manufacturer} ${model}`,
      type,
      bookValue: parseFloat(bookValue),
      salvageValue: parseFloat(salvageValue),
      manufacturer,
      model,
      modelYear,
      usage: parseInt(usage),
      condition,
      country,
      state,
      estimatedDepreciation: parseFloat(bookValue) * 0.06,
      fairMarketValue: parseFloat(bookValue) * 0.94, // time added also needs to be recorded for sorting purposes
      deleted : false,
    };

    const depreciation = {
      depreciationMethod: depreciationMethod === '' ? 'StraightLine' : depreciationMethod,
      costOfAsset: parseFloat(bookValue),
      salvageValue: parseFloat(salvageValue),
      usefulLife: usefulLife === '' ? 7 : parseInt(usefulLife),
      depreciationRate: depreciationRate === '' ? 0 : parseFloat(depreciationRate),
      totalExpectedUnitsOfProduction: totalExpectedUnitsOfProduction === '' ? 0 : parseInt(totalExpectedUnitsOfProduction),
      unitsProducedInYear: unitsProducedInYear === '' ? 0 : parseInt(unitsProducedInYear),
    };

    console.log('asset:', newAsset);

    if (!validateInputs()) {
      return;
    }

    createAssetService({
      userId: user.id,
      type: newAsset.type,
      bookValue: newAsset.bookValue,
      salvageValue: newAsset.salvageValue,
      manufacturer: newAsset.manufacturer,
      model: newAsset.model,
      modelYear: newAsset.modelYear,
      usage: newAsset.usage,
      condition: newAsset.condition,
      country: newAsset.country,
      state: newAsset.state,
      createDate: new Date().toISOString(),
      deleted: false,
      fairMarketValuesOverTime: [],
      assetDepreciationId: 0,
      depreciationMethod: depreciation.depreciationMethod,
      costOfAsset: depreciation.costOfAsset,
      usefulLife: depreciation.usefulLife,
      depreciationRate: depreciation.depreciationRate,
      totalExpectedUnitsOfProduction: depreciation.totalExpectedUnitsOfProduction,
      unitsProducedInYear: depreciation.unitsProducedInYear,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Asset created:', data);
        if (data.asset && Array.isArray(data.asset.fairMarketValuesOverTime)) {
          newAsset.fairMarketValuesOverTime = data.asset.fairMarketValuesOverTime.map(({ timeSet, value }) => ({
            timeSet: new Date(timeSet).toISOString(),
            value: parseFloat(value),
          }));
        }
        if (data.asset) {
          newAsset.assetId = data.asset.assetId;
        }
      })
      .catch(error => {
        console.error('Error creating asset:', error);
      });

    console.log('newAsset:', newAsset);
    addAsset(newAsset);
    handleClose();
  }

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    if (e.target.value !== 'US') {
      setState('');
    }
  };

  const handleDepreciationChange = (e) => {
    setDepreciationMethod(e.target.value);
    if (e.target.value === 'StraightLine') {
      setShowUsefulLife(true);
      setShowDepreciationRate(false);
      setShowTotalExpectedUnitsOfProduction(false);
      setShowUnitsProducedInYear(false);
      setDepreciationRate('');
      setTotalExpectedUnitsOfProduction('');
      setUnitsProducedInYear('');
    } else if (e.target.value === 'DecliningBalance') {
      setShowUsefulLife(true);
      setShowDepreciationRate(true);
      setShowTotalExpectedUnitsOfProduction(false);
      setShowUnitsProducedInYear(false);
      setTotalExpectedUnitsOfProduction('');
      setUnitsProducedInYear('');
    } else if (e.target.value === 'DoubleDecliningBalance') {
      setShowUsefulLife(true);
      setShowDepreciationRate(false);
      setShowTotalExpectedUnitsOfProduction(false);
      setShowUnitsProducedInYear(false);
      setDepreciationRate('');
      setTotalExpectedUnitsOfProduction('');
      setUnitsProducedInYear('');
    } else if (e.target.value === 'UnitsOfProduction') {
      setShowUsefulLife(false);
      setShowDepreciationRate(false);
      setShowTotalExpectedUnitsOfProduction(true);
      setShowUnitsProducedInYear(true);
      setUsefulLife('');
      setDepreciationRate('');
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Add New Asset</DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}
      >
        <DialogContentText>Enter your asset information</DialogContentText>

        <TextField
          select
          required
          label="Type"
          value={type}
          onChange={(e) => setType(e.target.value)}
          fullWidth
        >
          <MenuItem value="Equipment">Equipment</MenuItem>
          <MenuItem value="Vehicle">Vehicle</MenuItem>
        </TextField>

        <TextField
          required
          label="Initial Book Value"
          type="number"
          value={bookValue}
          onChange={(e) => setBookValue(e.target.value)}
          fullWidth
        />

        <TextField
          required
          label="Salvage Value"
          type="number"
          value={salvageValue}
          onChange={(e) => setSalvageValue(e.target.value)}
          fullWidth
        />

        <TextField
          required
          label="Manufacturer"
          value={manufacturer}
          onChange={(e) => setManufacturer(e.target.value)}
          fullWidth
        />

        <TextField
          required
          label="Model"
          value={model}
          onChange={(e) => setModel(e.target.value)}
          fullWidth
        />

        <TextField
          required
          label="Model Year"
          type="number"
          value={modelYear}
          onChange={(e) => setModelYear(e.target.value)}
          fullWidth
        />

        <TextField
          required
          label="Usage (in hours or miles)"
          type="number"
          value={usage}
          onChange={(e) => setUsage(e.target.value)}
          fullWidth
        />

        <TextField
          select
          required
          label="Condition"
          value={condition}
          onChange={(e) => setCondition(e.target.value)}
          fullWidth
        >
          <MenuItem value="Excellent">Excellent</MenuItem>
          <MenuItem value="Very Good">Very Good</MenuItem>
          <MenuItem value="Good">Good</MenuItem>
          <MenuItem value="Fair">Fair</MenuItem>
          <MenuItem value="Poor">Poor</MenuItem>
        </TextField>

        <TextField
          select
          required
          label="Country"
          value={country}
          onChange={handleCountryChange}
          fullWidth
        >
          <MenuItem value="US">United States</MenuItem>
          <MenuItem value="MX">Mexico</MenuItem>
        </TextField>

        {country === 'US' && (
          <TextField
            select
            required
            label="State"
            value={state}
            onChange={(e) => setState(e.target.value)}
            fullWidth
          >
            <MenuItem value="">- Select -</MenuItem>
            {states.map((state) => (
              <MenuItem key={state.abbreviation} value={state.abbreviation}>
                {state.name}
              </MenuItem>
            ))}
          </TextField>
        )}

        <Button
          fullWidth
          onClick={() => setShowDepreciation(!showDepreciation)}
          endIcon={<ExpandMoreIcon />}
          sx={{ mt: 2 }}
          color="success"
        >
          Asset Depreciation
        </Button>
        <Collapse in={showDepreciation}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            <TextField
              select
              label="Depreciation Schedule"
              value={depreciationMethod}
              onChange={handleDepreciationChange}
              fullWidth
            >
              <MenuItem value="StraightLine">Straight Line</MenuItem>
              <MenuItem value="DecliningBalance">Declining Balance</MenuItem>
              <MenuItem value="DoubleDecliningBalance">Double Declining Balance</MenuItem>
              <MenuItem value="UnitsOfProduction">Units Of Production</MenuItem>
            </TextField>
            {showUsefulLife && (
              <TextField
                name="usefulLife"
                label="Useful Life (Years)"
                type="number"
                value={usefulLife}
                onChange={(e) => setUsefulLife(e.target.value)}
                fullWidth
              />
            )}
            {showDepreciationRate && (
              <TextField
                name="depreciationRate"
                label="Depreciation Rate"
                type="number"
                value={depreciationRate}
                onChange={(e) => setDepreciationRate(e.target.value)}
                fullWidth
              />
            )}
            {showTotalExpectedUnitsOfProduction && (
              <TextField
                name="totalExpectedUnitsOfProduction"
                label="Expected Units of Production"
                type="number"
                value={totalExpectedUnitsOfProduction}
                onChange={(e) => setTotalExpectedUnitsOfProduction(e.target.value)}
                fullWidth
              />
            )}
            {showUnitsProducedInYear && (
              <TextField
                name="unitsProducedInYear"
                label="Units Produced in Year"
                type="number"
                value={unitsProducedInYear}
                onChange={(e) => setUnitsProducedInYear(e.target.value)}
                fullWidth
              />
            )}
          </Box>
        </Collapse>
      </DialogContent>

      <DialogActions sx={{ pb: 3, px: 3 }}>
        <Button color='success' onClick={handleClose}>Cancel</Button>
        <Button color='success' variant="contained" type="submit" onClick={handleAddAsset}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddAsset.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default AddAsset;