import { PieChart } from '@mui/x-charts/PieChart';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const DashboardPieChart = ({ data }) => {

  if (!data || data.length === 0) {
    return <Typography>No data available</Typography>;
  }

  return (
    <Card variant="outlined" sx={{ width: '100%' }}>
      <CardContent>
        <Typography component="h2" variant="subtitle2" gutterBottom>
          Manufacturer Percentage of Total Asset Value
        </Typography>
        <Stack sx={{ height: '312px', width: '600px', marginLeft: '5%' }}>
          <PieChart
            series={[
              {
                data: data,
                highlightScope: { fade: 'global', highlight: 'item' },
                innerRadius: 30,
                outerRadius: 150,
                cornerRadius: 4,
                startAngle: -45,
                endAngle: 315,
                cx: 210,
                width: 200,
              }
            ]}
          />
        </Stack>
      </CardContent>
    </Card>
  );
}

export default DashboardPieChart;