import { createContext, useState, useEffect } from "react";
import { getAssetsService } from "../services/assetService";
import { getLoansService } from "../services/loanService";

const AuthContext = createContext({});

const getInitialState = () => {
  const currentUser = sessionStorage.getItem("currentUser") ?? localStorage.getItem("currentUser");
  return currentUser ? JSON.parse(currentUser) : null;
}

const AuthProvider = ({children}) => {
  const [currentUser, setCurrentUser] = useState(getInitialState);

  useEffect(() => {
      sessionStorage.setItem("currentUser", JSON.stringify(currentUser));
  }, [currentUser])

  const setDemoUser = (user) => {
    setCurrentUser(user);
  };

  const setUser = async (user) => {
    if (user && user.fullName) {
        [user.firstName, user.lastName] = user.fullName.split(' ');
    }
    
    try {
      user.assets = await getAssetsService(user.id);
    } catch (error) {
      console.error("Error fetching assets:", error);
    }

    try {
      user.loans = await getLoansService(user.id);
    } catch (error) {
      console.error("Error fetching loans:", error);
    }

    setCurrentUser(user);
  };

  const logout = () => {
    setCurrentUser(null);
  }

  const addAsset = (newAsset) => {
    setUser((prevUser) => ({
      ...prevUser,
      assets: prevUser.assets ? [...prevUser.assets, { ...newAsset, id: prevUser.assets.length + 1 }] : [{ ...newAsset, id: 1 }],
    }));
  };

  const deleteAsset = () => {
    setUser((prevUser) => ({
      ...prevUser,
      assets: prevUser.assets.filter(asset => asset.deleted !== 1),
    }));
  }

  const getUserAssets = () => {
    setUser((prevUser) => ({
      ...prevUser,
      assets: prevUser.assets.filter(asset => asset.deleted !== 1),
    }));
  }

  const addLoan = (newLoan) => {
    setCurrentUser(prevUser => ({
      ...prevUser,
      loans: [...(prevUser.loans || []), newLoan]
    }));
  };

  const getUserLoans = () => {
    setUser((prevUser) => ({
      ...prevUser,
      loans: prevUser.loans,
    }));
  }




  return (
      <AuthContext.Provider 
        value={{user: currentUser, setUser, logout, addAsset, deleteAsset, getUserAssets, addLoan, getUserLoans, setDemoUser}}>
          { children }
      </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider }