export const DEMO_USER = {
  firstName: 'Demo', 
  lastName: 'User', 
  company: 'Demo Company', 
  email: 'demo@email.com', 
  assets: [
    {
      id: 1,
      type: 'Equipment',
      manufacturer: 'John Deere',
      model: '6R 150',
      modelYear: 2014,
      usage: 20000,
      condition: 'Fair',
      country: 'US',
      state: 'NE',
      bookValue: 21423.35,
      createDate: '2024-01-01T11:30:30',
      fairMarketValue: 19900.00,
      fairMarketValuesOverTime: [
        { timeSet: '2024-02-04T11:30:30', value: 20200.00 },
        { timeSet: '2024-03-04T11:30:30', value: 20150.00 },
        { timeSet: '2024-05-04T11:30:30', value: 20000.00 },
        { timeSet: '2024-06-04T11:30:30', value: 19950.00 },
        { timeSet: '2024-08-04T11:30:30', value: 19925.00 },
        { timeSet: '2024-10-04T11:30:30', value: 19910.00 },
        { timeSet: '2024-12-04T11:30:30', value: 19905.00 },
        { timeSet: '2025-02-04T11:30:30', value: 19920.00 },
      ],
      depreciationOverTime: [
        { timeSet: '2024-02-04T11:30:30', value: 20190.00 },
        { timeSet: '2024-03-04T11:30:30', value: 20166.00 },
        { timeSet: '2024-04-04T11:30:30', value: 20142.00 },
        { timeSet: '2024-05-04T11:30:30', value: 20118.00 },
        { timeSet: '2024-06-04T11:30:30', value: 20094.00 },
        { timeSet: '2024-07-04T11:30:30', value: 20070.00 },
        { timeSet: '2024-08-04T11:30:30', value: 20046.00 },
        { timeSet: '2024-09-04T11:30:30', value: 20022.00 },
        { timeSet: '2024-10-04T11:30:30', value: 19998.00 },
        { timeSet: '2024-12-04T11:30:30', value: 19974.00 },
        { timeSet: '2025-01-04T11:30:30', value: 19950.00 },
        { timeSet: '2025-02-04T11:30:30', value: 19926.00 },
        { timeSet: '2025-03-04T11:30:30', value: 19902.00 },
        { timeSet: '2025-03-04T11:30:30', value: 19878.00 },
        { timeSet: '2025-03-04T11:30:30', value: 19854.00 },
        { timeSet: '2025-03-04T11:30:30', value: 19830.00 },
      ],
    },
    {
      id: 2,
      type: 'Equipment',
      manufacturer: 'Case IH',
      model: 'Magnum 340',
      modelYear: 2019,
      usage: 20010,
      condition: 'Very Good',
      country: 'US',
      state: 'NE',
      bookValue: 17240.66,
      createDate: '2024-01-21T11:30:30',
      fairMarketValue: 15183.02,
      fairMarketValuesOverTime: [
        { timeSet: '2024-04-04T11:30:30', value: 15200.67 },
        { timeSet: '2024-05-04T11:30:30', value: 15098.54 },
        { timeSet: '2024-09-04T11:30:30', value: 14995.12 },
        { timeSet: '2024-10-04T11:30:30', value: 14893.77 },
        { timeSet: '2024-12-04T11:30:30', value: 14790.03 },
        { timeSet: '2025-01-04T11:30:30', value: 14687.55 },
        { timeSet: '2025-03-04T11:30:30', value: 14583.02 },
      ],
      depreciationOverTime: [
        { timeSet: '2024-02-04T11:30:30', value: 15300.00 },
        { timeSet: '2024-03-04T11:30:30', value: 15258.00 },
        { timeSet: '2024-04-04T11:30:30', value: 15216.00 },
        { timeSet: '2024-05-04T11:30:30', value: 15174.00 },
        { timeSet: '2024-06-04T11:30:30', value: 15132.00 },
        { timeSet: '2024-07-04T11:30:30', value: 15090.00 },
        { timeSet: '2024-08-04T11:30:30', value: 15048.00 },
        { timeSet: '2024-09-04T11:30:30', value: 15006.00 },
        { timeSet: '2024-10-04T11:30:30', value: 14964.00 },
        { timeSet: '2024-12-04T11:30:30', value: 14922.00 },
        { timeSet: '2025-01-04T11:30:30', value: 14880.00 },
        { timeSet: '2025-02-04T11:30:30', value: 14838.00 },
        { timeSet: '2025-03-04T11:30:30', value: 14796.00 },
        { timeSet: '2025-04-04T11:30:30', value: 14754.00 },
        { timeSet: '2025-05-04T11:30:30', value: 14712.00 },
        { timeSet: '2025-06-04T11:30:30', value: 14670.00 },
      ],
    },
    {
      id: 3,
      type: 'Equipment',
      manufacturer: 'New Holland',
      model: 'CR10.90',
      modelYear: 2013,
      usage: 100,
      condition: 'Good',
      country: 'US',
      state: 'NE',
      bookValue: 158244.91,
      createDate: '2024-02-15T11:30:30',
      fairMarketValue: 150202.44,
      fairMarketValuesOverTime: [
        { timeSet: '2024-03-04T11:30:30', value: 150224.91 },
        { timeSet: '2024-04-04T11:30:30', value: 150216.64 },
        { timeSet: '2024-05-04T11:30:30', value: 150214.74 },
        { timeSet: '2024-07-04T11:30:30', value: 150211.99 },
        { timeSet: '2024-09-04T11:30:30', value: 150207.05 },
        { timeSet: '2024-10-04T11:30:30', value: 150205.92 },
        { timeSet: '2024-11-04T11:30:30', value: 150206.71 },
        { timeSet: '2025-02-04T11:30:30', value: 150202.44 },
      ],
    },
    {
      id: 4,
      type: 'Equipment',
      manufacturer: 'Kubota',
      model: 'M7-171',
      modelYear: 2022,
      usage: 20000,
      condition: 'Very Good',
      country: 'US',
      state: 'NE',
      bookValue: 96112.45,
      createDate: '2024-03-21T11:30:30',
      fairMarketValue: 89097.39,
      fairMarketValuesOverTime: [
        { timeSet: '2024-04-04T11:30:30', value: 89102.45 },
        { timeSet: '2024-05-04T11:30:30', value: 89101.32 },
        { timeSet: '2024-07-04T11:30:30', value: 89100.98 },
        { timeSet: '2024-09-04T11:30:30', value: 89100.86 },
        { timeSet: '2024-10-04T11:30:30', value: 89099.29 },
        { timeSet: '2024-11-04T11:30:30', value: 89099.50 },
        { timeSet: '2025-02-04T11:30:30', value: 89097.39 },
      ],
    },
    {
      id: 5,
      type: 'Equipment',
      manufacturer: 'Claas',
      model: 'LEXION 8900',
      modelYear: 1997,
      usage: 2100,
      condition: 'Poor',
      country: 'US',
      state: 'NE',
      bookValue: 2144.50,
      createDate: '2024-03-21T11:30:30',
      fairMarketValue: 2090.87,
      fairMarketValuesOverTime: [
        { timeSet: '2024-04-04T11:30:30', value: 2099.57 },
        { timeSet: '2024-05-04T11:30:30', value: 2099.21 },
        { timeSet: '2024-06-04T11:30:30', value: 2097.50 },
        { timeSet: '2024-08-04T11:30:30', value: 2093.04 },
        { timeSet: '2024-09-04T11:30:30', value: 2093.45 },
        { timeSet: '2024-11-04T11:30:30', value: 2093.12 },
        { timeSet: '2025-01-04T11:30:30', value: 2090.87 },
      ],
    },
    {
      id: 6,
      type: 'Equipment',
      manufacturer: 'John Deere',
      model: '8R 410',
      modelYear: 2020,
      usage: 100000,
      condition: 'Good',
      country: 'US',
      state: 'NE',
      bookValue: 34800,
      createDate: '2024-05-21T11:30:30',
      fairMarketValue: 15689.09,
      fairMarketValuesOverTime: [
        { timeSet: '2024-06-04T11:30:30', value: 15708.00 },
        { timeSet: '2024-07-04T11:30:30', value: 15687.29 },
        { timeSet: '2024-08-04T11:30:30', value: 15720.60 },
        { timeSet: '2024-10-04T11:30:30', value: 15704.77 },
        { timeSet: '2024-12-04T11:30:30', value: 15690.40 },
        { timeSet: '2025-01-04T11:30:30', value: 15689.09 },
      ],
    },
    {
      id: 7,
      type: 'Equipment',
      manufacturer: 'Case IH',
      model: 'Steiger 620',
      modelYear: 2024,
      usage: 200,
      condition: 'Very Good',
      country: 'US',
      state: 'NE',
      bookValue: 5277.99,
      createDate: '2024-07-21T11:30:30',
      fairMarketValue: 2558.43,
      fairMarketValuesOverTime: [
        { timeSet: '2024-08-04T11:30:30', value: 2567.78 },
        { timeSet: '2024-09-04T11:30:30', value: 2568.92 },
        { timeSet: '2024-11-04T11:30:30', value: 2564.03 },
        { timeSet: '2024-12-04T11:30:30', value: 2561.65 },
        { timeSet: '2025-01-04T11:30:30', value: 2558.99 },
        { timeSet: '2025-02-04T11:30:30', value: 2558.43 },
      ],
    },
    {
      id: 8,
      type: 'Equipment',
      manufacturer: 'New Holland',
      model: 'T7.315',
      modelYear: 2019,
      usage: 6000,
      condition: 'Very Good',
      country: 'US',
      state: 'NE',
      bookValue: 149000,
      createDate: '2024-08-21T11:30:30',
      fairMarketValue: 100940.59,
      fairMarketValuesOverTime: [
        { timeSet: '2024-09-04T11:30:30', value: 100974.89 },
        { timeSet: '2024-10-04T11:30:30', value: 100965.29 },
        { timeSet: '2024-11-04T11:30:30', value: 100953.60 },
        { timeSet: '2024-12-04T11:30:30', value: 100948.22 },
        { timeSet: '2025-01-04T11:30:30', value: 100943.04 },
        { timeSet: '2025-02-04T11:30:30', value: 100940.59 },
      ],
    },
    {
      id: 9,
      type: 'Equipment',
      manufacturer: 'Kubota',
      model: 'M8-211',
      modelYear: 2005,
      usage: 20000,
      condition: 'Fair',
      country: 'US',
      state: 'NE',
      bookValue: 18180,
      createDate: '2024-10-01T11:30:30',
      fairMarketValue: 8167.59,
      fairMarketValuesOverTime: [
        { timeSet: '2024-10-04T11:30:30', value: 8170.29 },
        { timeSet: '2024-11-04T11:30:30', value: 8169.60 },
        { timeSet: '2024-12-04T11:30:30', value: 8169.22 },
        { timeSet: '2025-01-04T11:30:30', value: 8168.04 },
        { timeSet: '2025-02-04T11:30:30', value: 8167.59 },
      ],
    },
    {
      id: 10,
      type: 'Equipment',
      manufacturer: 'Claas',
      model: 'Xerion 5000',
      modelYear: 2021,
      usage: 17000,
      condition: 'Very Good',
      country: 'US',
      state: 'NE',
      bookValue: 280340.57,
      createDate: '2024-11-01T11:30:30',
      fairMarketValue: 180320.38,
      fairMarketValuesOverTime: [
        { timeSet: '2024-11-04T11:30:30', value: 180319.52 },
        { timeSet: '2024-12-04T11:30:30', value: 180318.30 },
        { timeSet: '2025-01-04T11:30:30', value: 180318.27 },
        { timeSet: '2025-02-04T11:30:30', value: 180320.38 },
      ],
    },
    {
      id: 11,
      type: 'Equipment',
      manufacturer: 'John Deere',
      model: 'S780',
      modelYear: 2024,
      usage: 400,
      condition: 'Excellent',
      country: 'US',
      state: 'NE',
      bookValue: 9850.98,
      createDate: '2024-12-01T11:30:30',
      fairMarketValue: 4849.55,
      fairMarketValuesOverTime: [
        { timeSet: '2024-12-04T11:30:30', value: 4850.98 },
        { timeSet: '2025-01-04T11:30:30', value: 4850.40 },
        { timeSet: '2025-02-04T11:30:30', value: 4849.55 },
      ],
    },
    {
      id: 12,
      type: 'Equipment',
      manufacturer: 'Case IH',
      model: 'Patriot 4440',
      modelYear: 2018,
      usage: 21000,
      condition: 'Good',
      country: 'US',
      state: 'NE',
      bookValue: 52300,
      createDate: '2025-01-01T11:30:30',
      fairMarketValue: 52281.31,
      fairMarketValuesOverTime: [
        { timeSet: '2025-01-04T11:30:30', value: 52282.60 },
        { timeSet: '2025-02-04T11:30:30', value: 52281.31 },
      ],
    },
    {
      id: 13,
      type: 'Equipment',
      manufacturer: 'New Holland',
      model: 'BigBaler 340',
      modelYear: 2023,
      usage: 350,
      condition: 'Very Good',
      country: 'US',
      state: 'NE',
      bookValue: 13000,
      createDate: '2025-01-01T11:30:30',
      fairMarketValue: 12992.68,
      fairMarketValuesOverTime: [
        { timeSet: '2025-01-04T11:30:30', value: 12994.37 },
        { timeSet: '2025-02-04T11:30:30', value: 12992.68 },
      ],
    },
    {
      id: 14,
      type: 'Equipment',
      manufacturer: 'Kubota',
      model: 'M7-171',
      modelYear: 2022,
      usage: 460,
      condition: 'Very Good',
      country: 'US',
      state: 'NE',
      bookValue: 18550.65,
      createDate: '2025-02-01T11:30:30',
      fairMarketValue: 18321.10,
      fairMarketValuesOverTime: [
        { timeSet: '2025-02-04T11:30:30', value: 18421.10 },
        { timeSet: '2025-03-04T11:30:30', value: 18321.10 },
      ],
    },
    {
      id: 15,
      type: 'Equipment',
      manufacturer: 'Claas',
      model: 'Jaguar 960',
      modelYear: 2020,
      usage: 1200,
      condition: 'Fair',
      country: 'US',
      state: 'NE',
      bookValue: 3505.57,
      createDate: '2025-02-02T11:30:30',
      fairMarketValue: 3470.05,
      fairMarketValuesOverTime: [
        { timeSet: '2025-02-04T11:30:30', value: 3470.05 },
      ],
    },
  ],
  loans: [
    {
      id: 1,
      assetId: 1,
      lenderName: 'Farmers Bank',
      loanAmount: 20000,
      interestRate: 3.5,
      loanTermYears: 7,
      remainingBalance: 22000,
      monthlyPayment: 268.80,
      paymentFrequency: 'Monthly',
      status: 'Active',
      lastPaymentDate: '2024-03-01',
      lastPaymentAmount: 1200,
      nextPaymentDate: '2024-04-01',
      loanStartDate: '2023-01-01',
      loanEndDate: '2053-01-01'
    },
    {
      id: 2,
      assetId: 2,
      lenderName: 'Farmers Bank',
      loanAmount: 50000,
      interestRate: 4.5,
      loanTermYears: 15,
      remainingBalance: 45000,
      monthlyPayment: 382.50,
      paymentFrequency: 'Monthly',
      status: 'Active',
      lastPaymentDate: '2024-03-01',
      lastPaymentAmount: 2000,
      nextPaymentDate: '2024-04-01',
      loanStartDate: '2022-01-01',
      loanEndDate: '2037-01-01'
    },
    {
      id: 3,
      assetId: 3,
      lenderName: 'Farmers Bank',
      loanAmount: 100000,
      interestRate: 3.0,
      loanTermYears: 20,
      remainingBalance: 90000,
      monthlyPayment: 554.60,
      paymentFrequency: 'Monthly',
      status: 'Active',
      lastPaymentDate: '2025-03-01',
      lastPaymentAmount: 5000,
      nextPaymentDate: '2025-04-01',
      loanStartDate: '2025-01-01',
      loanEndDate: '2045-01-01'
    },
    {
      id: 4,
      assetId: 4,
      lenderName: 'Farmers Bank',
      loanAmount: 50000,
      interestRate: 3.5,
      loanTermYears: 30,
      remainingBalance: 45000,
      monthlyPayment: 224.52,
      paymentFrequency: 'Monthly',
      status: 'Active',
      lastPaymentDate: '2024-03-01',
      lastPaymentAmount: 2000,
      nextPaymentDate: '2024-04-01',
      loanStartDate: '2022-01-01',
      loanEndDate: '2052-01-01'
    },
  ]
}