import * as React from 'react';
import PropTypes from 'prop-types'; //add requirements to the row prop for the attributes used

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import { LineChart } from '@mui/x-charts/LineChart';
import Typography from '@mui/material/Typography';

import formatCurrencyUSD from '../../internals/formatCurrency';
import EditAsset from './EditAsset';
import { deleteAssetService } from '../../services/assetService';
import { AuthContext } from '../../context/AuthContext';
import DashboardHelper from '../../internals/dashboardHelper';
import TimeHelper from '../../internals/timeHelper';

function AreaGradient({ color, id }) {
  return (
    <defs>
      <linearGradient id={id} x1="50%" y1="0%" x2="50%" y2="100%">
        <stop offset="0%" stopColor={color} stopOpacity={0.3} />
        <stop offset="100%" stopColor={color} stopOpacity={0} />
      </linearGradient>
    </defs>
  );
}

AreaGradient.propTypes = {
  color: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

const AssetTableRow = ({ row }) => {
  const { deleteAsset } = React.useContext(AuthContext);
  const [expanded, setExpanded] = React.useState(false);
  const [openEditAsset, setOpenEditAsset] = React.useState(false);

  const assetValuationOverTime = DashboardHelper.fairMarketValueOverTime([row], TimeHelper.generateMonthlyTimeSeries());

  const times = assetValuationOverTime.map(entry => entry.date);
  const valuations = assetValuationOverTime.map(entry => entry.valuation);

  const depreciation = row.model === '6R 150' ?
    [20166.00,
      20142.00,
      20118.00,
      20094.00,
      20070.00,
      20046.00,
      20022.00,
      19998.00,
      19974.00,
      19950.00,
      19926.00,
      19902.00]
    : row.model === 'Magnum 340' ?
      [15258.00,
        15216.00,
        15174.00,
        15132.00,
        15090.00,
        15048.00,
        15006.00,
        14964.00,
        14922.00,
        14880.00,
        14838.00,
        14796.00]
      : null;

  const handleClickEdit = () => {
    setOpenEditAsset(true);
  }

  const handleCloseEditAsset = () => {
    setOpenEditAsset(false);
  };

  const handleClickDelete = () => {
    const confirmDelete = window.confirm(`Are you sure you want to delete the asset "${row.name}"?`);
    if (!confirmDelete) return;

    const asset = {
      id: row.id,
      name: row.name,
      type: row.type,
      bookValue: row.bookValue,
      salvageValue: row.salvageValue,
      manufacturer: row.manufacturer,
      model: row.model,
      modelYear: row.modelYear,
      usage: row.usage,
      condition: row.condition,
      country: row.country,
      state: row.state,
      estimatedDepreciation: row.estimatedDepreciation,
      fairMarketValue: row.fairMarketValue,
      deleted: row.deleted,
    };

    deleteAssetService(asset)
      .catch(error => {
        console.error('Error deleting asset:', error);
      });

    deleteAsset();

  };

  row.estimatedDepreciation = (row.bookValue - row.fairMarketValue) / 7;

  const fairMarketChart = () => {
    if (row.fairMarketValue === 0) {
      return (
        <Typography variant='body2' sx={{ marginTop: '10px'}}>
          No Fair Market Data Found
        </Typography>
      );
    } else if (!depreciation) {
      return (
        <LineChart
          series={[
            { data: valuations, label: 'Fair Market' },
          ]}
          height={300}
          width={750}
          xAxis={[{ scaleType: 'point', data: times }]}
          margin={{ left: 85 }}
        />
      );
    } else {
      return (
        <LineChart
          series={[
            { data: valuations, label: 'Fair Market' },
            { data: depreciation, label: 'Depreciation Schedule' },
          ]}
          height={300}
          width={750}
          xAxis={[{ scaleType: 'point', data: times }]}
          margin={{ left: 85 }}
        />
      );
    }
  }

  return (
    <>
      <TableRow onClick={() => setExpanded(!expanded)} sx={{ '& > *': { borderBottom: 'unset', cursor: 'pointer' } }}>
        <TableCell sx={{ paddingRight: 0 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            sx={{ padding: '5px 0' }}
          >
            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.manufacturer + ' ' + row.model + ' (' + row.modelYear + ')'}
        </TableCell>
        <TableCell align="right">{formatCurrencyUSD(row.bookValue)}</TableCell>
        <TableCell align="right">{formatCurrencyUSD(row.estimatedDepreciation)}</TableCell>
        <TableCell align="right">{formatCurrencyUSD((row.fairMarketValuesOverTime?.[row.fairMarketValuesOverTime.length - 1]?.value) || 0)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={expanded} timeout="auto" unmountOnExit >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ margin: 0, display: 'flex' }}>
                <Box sx={{ margin: '0 0 20px 10px' }}>
                  {fairMarketChart()}
                </Box>
              </Box>
              <Box sx={{ margin: 0, display: 'flex' }}>
                <IconButton
                  hover
                  title='Edit'
                  aria-label="edit asset"
                  size="medium"
                  color='success'
                  onClick={handleClickEdit}
                >
                  <EditSharpIcon />
                </IconButton>
                <IconButton
                  title='Delete'
                  aria-label="delete asset"
                  size="medium"
                  onClick={handleClickDelete}
                >
                  <DeleteSharpIcon />
                </IconButton>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
        <EditAsset open={openEditAsset} handleClose={handleCloseEditAsset} asset={row} />
      </TableRow>
    </>
  );
}

export default AssetTableRow;