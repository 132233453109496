import React, { useContext, useState } from 'react';
import { 
  Box, Typography, Button, Paper, TextField, 
  Dialog, DialogTitle, DialogContent, DialogActions,  Collapse
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import FilterIcon from '@mui/icons-material/FilterAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoanTable from './LoanTable';
import AddLoan from './AddLoan';
import formatCurrencyUSD from '../../internals/formatCurrency';
import { AuthContext } from '../../context/AuthContext';
import { createLoanRecordService, updateLoanService, deleteLoanService } from '../../services/loanService';

const Loans = () => {
  const { user, addLoan, getUserLoans } = useContext(AuthContext);
  const loans = user?.loans || [];
  
  const [searchTerm, setSearchTerm] = useState('');
  const [openAddLoan, setOpenAddLoan] = useState(false);
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [showAdvanced, setShowAdvanced] = useState(false);

  const handleAddLoan = () => {
    setSelectedLoan(null);
    setShowAdvanced(false);
    setOpenAddLoan(true);
  };

  const handleViewDetails = (loan) => {
    setSelectedLoan(loan);
    setShowAdvanced(false);
    setOpenDetailsDialog(true);
  };

  const handleEdit = (loan) => {
    setSelectedLoan({...loan}); 
    setShowAdvanced(false);
    setOpenDetailsDialog(false);
    setOpenAddLoan(true);
  };

  const handleDelete = (id) => {
    // Implement the delete logic
  };

  const handleCloseDetails = () => {
    setOpenDetailsDialog(false);
    setShowAdvanced(false);
    setSelectedLoan(null);
  };

  const handleCloseAddLoan = () => {
    setOpenAddLoan(false);
    setShowAdvanced(false);
    setSelectedLoan(null);
  };

  const filteredLoans = loans.filter(loan => 
    loan.lenderName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    loan.status.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSaveLoan = async (newLoan) => {
    try {
      const response = await createLoanRecordService({
        assetId: newLoan.assetId,
        userId: newLoan.userId,
        lenderName: newLoan.lenderName,
        loanAmount: newLoan.loanAmount,
        interestRate: newLoan.interestRate,
        loanTermYears: newLoan.loanTermYears,
        remainingBalance: newLoan.remainingBalance,
        monthlyPayment: newLoan.monthlyPayment,
        paymentFrequency: newLoan.paymentFrequency,
        status: newLoan.status,
        lastPaymentDate: newLoan.lastPaymentDate,
        lastPaymentAmount: newLoan.lastPaymentAmount === '' ? 0 : newLoan.lastPaymentAmount,
        nextPaymentDate: newLoan.nextPaymentDate,
        loanStartDate: newLoan.loanStartDate,
        loanEndDate: newLoan.loanEndDate,
      });

      const data = await response.json();
      if (data?.loan?.monthlyPayment !== undefined) {
        newLoan.loanId = data.loan.loanId;
        newLoan.monthlyPayment = parseFloat(data.loan.monthlyPayment);
      } else {
        console.error('Invalid loan data:', data); // Log invalid data for debugging
        throw new Error('Invalid loan data received from the server');
      }
      
      if (response.ok) {
        console.log('New loan:', newLoan);
        addLoan(newLoan);
        handleCloseAddLoan();
      } else {
        throw new Error('Failed to create loan');
      }
    } catch (error) {
      console.error('Error creating loan:', error);
    }
  };

  return (
    <Box className='Loan manager-box' sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h4" className='title'>Loan Management</Typography>
        <Button 
          variant="contained" 
          color="success" 
          startIcon={<AddIcon />} 
          onClick={handleAddLoan}
          sx={{ height: '40px' }}
        >
          Add New Loan
        </Button>
      </Box>

      <Paper
        component="form"
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%', border: '1px solid lightgray', mb: 3 }}
      >
        <SearchIcon sx={{ ml: 1 }} />
        <TextField
          fullWidth
          variant="standard"
          placeholder="Search loans..."
          sx={{ ml: 1, flex: 1 }}
          InputProps={{
            disableUnderline: true,
          }}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <FilterIcon sx={{ mr: 1 }} />
      </Paper>

      <Paper className='loan-table'>
        <LoanTable 
          loans={filteredLoans}
          onView={handleViewDetails}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      </Paper>

      <AddLoan 
        open={openAddLoan} 
        handleClose={handleCloseAddLoan}
        onSave={handleSaveLoan}
        user={user}
        loan={selectedLoan}
      />

      
      <Dialog
        open={openDetailsDialog}
        onClose={handleCloseDetails}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Loan Details</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Typography><strong>Lender:</strong> {selectedLoan?.lenderName}</Typography>
            <Typography><strong>Amount:</strong> {formatCurrencyUSD(selectedLoan?.loanAmount)}</Typography>
            <Typography><strong>Interest Rate:</strong> {selectedLoan?.interestRate}%</Typography>
            <Typography><strong>Term:</strong> {selectedLoan?.loanTermYears} years</Typography>
            <Typography><strong>Remaining Balance:</strong> {formatCurrencyUSD(selectedLoan?.remainingBalance)}</Typography>
            <Typography><strong>Monthly Payment:</strong> {formatCurrencyUSD(selectedLoan?.monthlyPayment)}</Typography>
            <Typography><strong>Payment Frequency:</strong> {selectedLoan?.paymentFrequency}</Typography>
            <Typography><strong>Status:</strong> {selectedLoan?.status}</Typography>
            
            <Button
              fullWidth
              onClick={() => setShowAdvanced(!showAdvanced)}
              endIcon={<ExpandMoreIcon />}
              sx={{ mt: 2 }}
              color="success"
            >
              Advanced Options
            </Button>

            <Collapse in={showAdvanced}>
              <Box sx={{ mt: 2 }}>
                <Typography><strong>Last Payment Date:</strong> {selectedLoan?.lastPaymentDate}</Typography>
                <Typography><strong>Last Payment Amount:</strong> {formatCurrencyUSD(selectedLoan?.lastPaymentAmount)}</Typography>
                <Typography><strong>Next Payment Date:</strong> {selectedLoan?.nextPaymentDate}</Typography>
                <Typography><strong>Start Date:</strong> {selectedLoan?.loanStartDate}</Typography>
                <Typography><strong>End Date:</strong> {selectedLoan?.loanEndDate}</Typography>
              </Box>
            </Collapse>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetails} color="success">
            Close
          </Button>
          <Button 
            onClick={() => handleEdit(selectedLoan)} 
            variant="contained" 
            color="success"
          >
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Loans;