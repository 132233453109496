import { 
  BACKEND_BASE_URL,
  CREATE_LOAN,
  GET_LOAN,
  UPDATE_LOAN,
  DELETE_LOAN,
} from "../constants/apiConstants";

export async function createLoanRecordService(data) {
  const response = await fetch(BACKEND_BASE_URL + CREATE_LOAN, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
  return await response; 
}

export async function getLoansService(data) {
  const response = await fetch(BACKEND_BASE_URL + GET_LOAN, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
  return await response.json(); 
}

  export async function updateLoanService(data) {
      const response = await fetch(BACKEND_BASE_URL + UPDATE_LOAN, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
      })
      return await response; 
  }

  export async function deleteLoanService(data) {
    const response = await fetch(BACKEND_BASE_URL + DELETE_LOAN, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
    })
    return await response;
  }