import React from 'react';
import PropTypes from 'prop-types';
import {
  TableRow,
  TableCell,
  IconButton,
  Tooltip
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import formatCurrencyUSD from '../../internals/formatCurrency';

const LoanTableRow = ({ loan, onView, onEdit, onDelete }) => {
  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this loan?')) {
      onDelete(loan.id);
    }
  };

  return (
    <TableRow hover>
      <TableCell sx={{ paddingRight: 0 }}></TableCell>
      <TableCell component='th' scope='loan'>{loan.lenderName}</TableCell>
      <TableCell align="right">{formatCurrencyUSD(loan.loanAmount)}</TableCell>
      <TableCell align="right">{loan.interestRate}%</TableCell>
      <TableCell align="right">{loan.loanTermYears}</TableCell>
      <TableCell align="right">{formatCurrencyUSD(loan.remainingBalance)}</TableCell>
      <TableCell align="right">{formatCurrencyUSD(loan.monthlyPayment)}</TableCell>
      <TableCell align="right">{loan.status}</TableCell>
      <TableCell align="right">
        <Tooltip title="View Details">
          <IconButton onClick={() => onView(loan)} size="small" color="success">
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit">
          <IconButton onClick={() => onEdit(loan)} size="small" color="success">
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton onClick={handleDelete} size="small" color="error">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

LoanTableRow.propTypes = {
  loan: PropTypes.shape({
    id: PropTypes.number.isRequired,
    lenderName: PropTypes.string.isRequired,
    loanAmount: PropTypes.number.isRequired,
    interestRate: PropTypes.number.isRequired,
    loanTermYears: PropTypes.number.isRequired,
    remainingBalance: PropTypes.number.isRequired,
    monthlyPayment: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired
  }).isRequired,
  onView: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default LoanTableRow;
