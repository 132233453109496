import * as React from 'react';
import { Link } from 'react-router-dom';

import { ASSET_MANAGER_URL, DASHBOARD_URL, ASSETS_URL, SETTINGS_URL, VALUE_FINDER_URL, LOANS_URL} from '../constants/pathConstants';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InventoryIcon from '@mui/icons-material/Inventory';
import SettingsIcon from '@mui/icons-material/Settings';
import InfoIcon from '@mui/icons-material/Info';
import TimelineIcon from '@mui/icons-material/Timeline';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const SideBar = ({ sideBarOpen, onClose }) => {
  return (
    <Drawer open={sideBarOpen} onClose={onClose} onClick={onClose}>
      <Box sx={{ width: 280,}} role="presentation">
        <img src={`${process.env.PUBLIC_URL}/assets/DPA.png`} alt="Logo" style={{ height: '60px', width: 'auto', margin: '3px 0 20px 75px' }} />
        <List>
          <ListItem key={'Dashboard'} disablePadding sx={{ marginBottom: '5px' }}>
            <Link to={ASSET_MANAGER_URL + DASHBOARD_URL} className='sidebar-link'>
              <ListItemButton sx={{ paddingLeft: '40px'}}>
                <ListItemIcon>
                  <DashboardIcon style={{ fontSize: 28 }} />
                </ListItemIcon>
                <Typography variant='h6'>
                  Dashboard
                </Typography>
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem key={'Assets'} disablePadding sx={{ marginBottom: '5px' }}>
            <Link to={ASSET_MANAGER_URL + ASSETS_URL} className='sidebar-link'>
              <ListItemButton sx={{ paddingLeft: '40px'}}>
                <ListItemIcon>
                  <InventoryIcon style={{ fontSize: 28 }} />
                </ListItemIcon>
                <Typography variant='h6'>
                  Assets
                </Typography>
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem key={'Loans'} disablePadding sx={{ marginBottom: '5px' }}>
            <Link to={ASSET_MANAGER_URL + LOANS_URL} className='sidebar-link'>
              <ListItemButton sx={{ paddingLeft: '40px'}}>
                <ListItemIcon>
                  <AccountBalanceIcon style={{ fontSize: 28 }} />
                </ListItemIcon>
                <Typography variant='h6'>
                  Loans
                </Typography>
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem key={'Settings'} disablePadding sx={{ marginBottom: '5px' }}>
            <Link to={ASSET_MANAGER_URL + SETTINGS_URL} className='sidebar-link'>
              <ListItemButton sx={{ paddingLeft: '40px'}}>
                <ListItemIcon>
                  <SettingsIcon style={{ fontSize: 28 }} />
                </ListItemIcon>
                <Typography variant='h6'>
                  Settings
                </Typography>
              </ListItemButton>
            </Link>
          </ListItem>
          {/* TODO
          <ListItem key={'Info'} disablePadding>
            <Link to={ASSET_MANAGER_URL + DASHBOARD_URL} className='sidebar-link'>
              <ListItemButton>
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                <ListItemText primary={'Info'} />
              </ListItemButton>
            </Link>
          </ListItem> */}
        </List>
      </Box>
    </Drawer>
  );
}

export default SideBar;