import React, { useState, useContext } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

import { ASSET_MANAGER_URL, DASHBOARD_URL } from '../../constants/pathConstants';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';

import { signUpService } from '../../services/userService';

import { AuthContext } from '../../context/AuthContext';

const SignUp = () => {
  const { setUser } = useContext(AuthContext);
  const [firstName, setFirstName] = useState({ value: '', error: false, message: '' });
  const [lastName, setLastName] = useState({ value: '', error: false, message: '' });
  const [username, setUsername] = useState({ value: '', error: false, message: '' });
  const [email, setEmail] = useState({ value: '', error: false, message: '' });
  const [password, setPassword] = useState({ value: '', error: false, message: '' });

  const navigate = useNavigate();

  const validateInputs = () => {
    let isValid = true;

    if (!/\S+@\S+\.\S+/.test(email.value)) {
      setEmail({ ...email, error: true, message: 'Please enter a valid email address.' });
      isValid = false;
    } else {
      setEmail({ ...email, error: false, message: '' });
    }

    if (!password.value || password.value.length < 6) {
      setPassword({ ...password, error: true, message: 'Password must be at least 6 characters long.' });
      isValid = false;
    } else {
      setPassword({ ...password, error: false, message: '' });
    }

    if (!firstName.value || firstName.value.length < 1) {
      setFirstName({ ...firstName, error: true, message: 'First name is required.' });
      isValid = false;
    } else {
      setFirstName({ ...firstName, error: false, message: '' });
    }

    if (!lastName.value || lastName.value.length < 1) {
      setLastName({ ...lastName, error: true, message: 'Last name is required.' });
      isValid = false;
    } else {
      setLastName({ ...lastName, error: false, message: '' });
    }

    if (!username.value || username.value.length < 1) {
      setUsername({ ...username, error: true, message: 'Username is required.' });
      isValid = false;
    } else {
      setUsername({ ...username, error: false, message: '' });
    }

    return isValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateInputs()) {
      return;
    }

    // Pass validated data to the service
    signUpService({
      firstName: firstName.value,
      lastName: lastName.value,
      username: username.value,
      email: email.value,
      password: password.value,
    })
      .then((response) => response.json())
      .then((data) => {
        setUser(data);
        navigate(ASSET_MANAGER_URL + DASHBOARD_URL);
      })
      .catch((error) => {
        console.error('There has been an error signing up:', error);
      });
  };

  return (
    <Stack className='sign-in-container' direction="column" justifyContent="space-between">
      <MuiCard className='sign-in-card' variant="outlined">
        <img src={`${process.env.PUBLIC_URL}/assets/DPA.png`} alt="Logo" style={{ height: '100px', width: '250px', marginLeft: '60px' }} />
        <Typography component="h1" variant="h4" sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}>
          Sign up
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <FormControl>
            <FormLabel htmlFor="firstName">First Name</FormLabel>
            <TextField
              value={firstName.value}
              onChange={(e) => setFirstName({ ...firstName, value: e.target.value })}
              autoComplete="firstName"
              required
              fullWidth
              id="firstName"
              placeholder="Jon"
              error={firstName.error}
              helperText={firstName.message}
              color={firstName.error ? 'error' : 'primary'}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="lastName">Last Name</FormLabel>
            <TextField
              value={lastName.value}
              onChange={(e) => setLastName({ ...lastName, value: e.target.value })}
              autoComplete="lastName"
              required
              fullWidth
              id="lastName"
              placeholder="Snow"
              error={lastName.error}
              helperText={lastName.message}
              color={lastName.error ? 'error' : 'primary'}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="username">Username</FormLabel>
            <TextField
              value={username.value}
              onChange={(e) => setUsername({ ...username, value: e.target.value })}
              autoComplete="username"
              required
              fullWidth
              id="username"
              placeholder="jsnow"
              error={username.error}
              helperText={username.message}
              color={username.error ? 'error' : 'primary'}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="email">Email</FormLabel>
            <TextField
              value={email.value}
              onChange={(e) => setEmail({ ...email, value: e.target.value })}
              required
              fullWidth
              id="email"
              placeholder="your@email.com"
              name="email"
              autoComplete="email"
              variant="outlined"
              error={email.error}
              helperText={email.message}
              color={email.error ? 'error' : 'primary'}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="password">Password</FormLabel>
            <TextField
              value={password.value}
              onChange={(e) => setPassword({ ...password, value: e.target.value })}
              required
              fullWidth
              name="password"
              placeholder="••••••"
              type="password"
              id="password"
              autoComplete="new-password"
              variant="outlined"
              error={password.error}
              helperText={password.message}
              color={password.error ? 'error' : 'primary'}
            />
          </FormControl>
          <Button type="submit" fullWidth variant="contained">
            Sign up
          </Button>
          <Typography sx={{ textAlign: 'center' }}>
            Already have an account?{' '}
            <span>
              <RouterLink to={'/sign-in'}>
                Sign in
              </RouterLink>
            </span>
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <RouterLink to={'/'}>
            <Button
              fullWidth
              variant="outlined"
            >
              Cancel
            </Button>
          </RouterLink>
        </Box>
      </MuiCard>
    </Stack>
  );
};

export default SignUp;
