import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';
import { areaElementClasses } from '@mui/x-charts/LineChart';

import formatCurrencyUSD from '../../internals/formatCurrency'

function StatCard({ title, data, format }) {
  const theme = useTheme();

  // Extract valuation and time data
  const times = data.map(entry => entry.date);
  const valuations = data.map(entry => entry.valuation);

  // Compute percentage change in the past year
  const latestValuation = valuations[valuations.length - 1] || 0;
  const oneMonthAgoValuation = valuations[valuations.length - 2] || 0;

  const percentage = oneMonthAgoValuation
    ? (((latestValuation - oneMonthAgoValuation) / oneMonthAgoValuation) * 100).toFixed(2) + "%"
    : "N/A";

  // Determine percentage color
  const percentageColor = latestValuation >= oneMonthAgoValuation ? "success" : "error";

  const chartColor = theme.palette.grey[500]; // line color
  const interval = 'Past Month'

  const graphAreaId = latestValuation;

  return (
    <Card variant="outlined" sx={{ height: '100%', flexGrow: 1 }}>
      <CardContent sx={{ pb: '10px !important'}}>
        <Typography component="h2" variant="subtitle2" gutterBottom>
          {title}
        </Typography>
        <Stack
          direction="column"
          sx={{ justifyContent: 'space-between', flexGrow: '1', gap: 0 }}
        >
          <Stack sx={{ justifyContent: 'space-between' }}>
            <Stack
              direction="row"
              sx={{ justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Typography variant="h4" component="p">
                {format === 'dollars' ? formatCurrencyUSD(latestValuation) : ''}{format === 'percentage' ? latestValuation.toFixed(2) + '%' : ''}
              </Typography>
              {percentage && interval && <div style={{display: 'flex', flexDirection: 'column', marginTop: '-5px' }}>
                <Chip size="small" color={percentageColor} label={percentage} />
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                  {interval}
                </Typography>
              </div>}
            </Stack>
          </Stack>
          <Box sx={{ width: '110%', height: 120, marginLeft: '-5%' }}>
            <SparkLineChart
              colors={[chartColor]}
              data={valuations}
              area
              showHighlight
              showTooltip
              xAxis={{
                scaleType: 'band',
                data: times,
              }}
              sx={{
                [`& .${areaElementClasses.root}`]: {
                  fill: `url(#area-gradient-${graphAreaId})`,
                },
              }}
            >
              <AreaGradient color={chartColor} id={`area-gradient-${graphAreaId}`} />
            </SparkLineChart>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
}

// StatCard.propTypes = {
//   data: PropTypes.arrayOf(PropTypes.number).isRequired,
//   title: PropTypes.string.isRequired,
// };

function AreaGradient({ color, id }) {
  return (
    <defs>
      <linearGradient id={id} x1="50%" y1="0%" x2="50%" y2="100%">
        <stop offset="0%" stopColor={color} stopOpacity={0.3} />
        <stop offset="100%" stopColor={color} stopOpacity={0} />
      </linearGradient>
    </defs>
  );
}

AreaGradient.propTypes = {
  color: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default StatCard;