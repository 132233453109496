// Comment out this line when deploying to repo
//export const BACKEND_BASE_URL = 'https://localhost:5001'
export const BACKEND_BASE_URL = 'https://app-asset-manager-backend-dev-gvbcgug7euauhpdu.centralus-01.azurewebsites.net' // Then uncomment this line to use the deployed backend

// Account Controller Endpoints
export const REGISTER = '/Register'
export const LOGIN = '/Login'
export const DELETE = '/DeleteUser'
export const UPDATE = '/UpdateUser'

// Asset Controller Endpoints
export const CREATE_ASSET = '/CreateAsset'

// Loan Controller Endpoints
export const CREATE_LOAN = '/CreateLoanRecord'
export const GET_LOAN = '/GetLoans'
export const UPDATE_LOAN = '/UpdateLoan'
export const DELETE_LOAN = '/DeleteLoan'

export const DELETE_ASSET = '/DeleteAsset'

