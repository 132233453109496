import { parseISO, isBefore, isEqual, compareDesc } from "date-fns";

const DashboardHelper = {
  assetValuationOverTime: (assets, timeSeries) => {
    return timeSeries.map(dateStr => {
      const date = parseISO(dateStr);
      const valuation = assets.reduce((total, asset) => {
        const assetDate = parseISO(asset.createDate);
        return total + (isBefore(assetDate, date) || isEqual(assetDate, date) ? asset.bookValue : 0);
      }, 0);
      return { date: dateStr, valuation };
    });
  },

  fairMarketValueOverTime: (assets, timeSeries) => {
    return timeSeries.map(dateStr => {
      const date = parseISO(dateStr);
      const valuation = assets.reduce((total, asset) => {
        if(!asset.fairMarketValuesOverTime) {
          return 0;
        }
        const assetFairMarketValue = asset.fairMarketValuesOverTime
          .filter(entry => isBefore(parseISO(entry.timeSet), date))
          .sort((a, b) => compareDesc(parseISO(a.timeSet), parseISO(b.timeSet)))[0];

        return total + (assetFairMarketValue ? assetFairMarketValue.value : 0);
      }, 0);
      return { date: dateStr, valuation };
    });
  },

  projectedRoiOverTime: (assets, timeSeries) => {
    return timeSeries.map(dateStr => {
      const date = parseISO(dateStr);
      const totalRoi = assets.reduce((total, asset) => {
        if(!asset.fairMarketValuesOverTime) {
          return 0;
        }
        const assetFairMarketValue = asset.fairMarketValuesOverTime
          .filter(entry => isBefore(parseISO(entry.timeSet), date) || isEqual(parseISO(entry.timeSet), date))
          .sort((a, b) => compareDesc(parseISO(a.timeSet), parseISO(b.timeSet)))[0];

        return total + (assetFairMarketValue ? (assetFairMarketValue.value - asset.bookValue) / asset.bookValue : 0);
      }, 0);
      const valuation = (totalRoi / assets.length) * 100;
      return { date: dateStr, valuation };
    });
  },

  debtOverTime: (loans, timeSeries) => {
    return timeSeries.map(dateStr => {
      const date = parseISO(dateStr);
      const totalDebt = loans.reduce((total, loan) => {
        return total + loan.remainingBalance;
      }, 0);
      return { date: dateStr, valuation: totalDebt };
    });
  },

  percentOfTotalAssetValueByManufacturer: (assets) => {
    if (!assets || assets.length === 0) return [];

    const totalValue = assets.reduce((sum, asset) => sum + asset.bookValue, 0);
    const manufacturerValues = assets.reduce((acc, asset) => {
      acc[asset.manufacturer] = (acc[asset.manufacturer] || 0) + asset.bookValue;
      return acc;
    }, {});

    return Object.entries(manufacturerValues).map(([manufacturer, percentage]) => ({
      label: manufacturer,
      value: (percentage / totalValue) * 100,
    }));
  },
};

export default DashboardHelper;

