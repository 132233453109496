import { subMonths, format } from "date-fns";

const TimeHelper = {
  generateMonthlyTimeSeries: (months = 12, startOffset = 0) => {
    return Array.from({ length: months }, (_, i) => 
      format(subMonths(new Date(), i + startOffset), "yyyy-MM-dd")
    ).reverse();
  },
};

export default TimeHelper;
