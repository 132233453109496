import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  MenuItem,
  Collapse,
  Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AddLoan = ({ user, open, handleClose, onSave, loan }) => {
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [newLoan, setNewLoan] = useState({
    assetId: '',
    userId: user.id,
    lenderName: '',
    loanAmount: '',
    interestRate: '',
    loanTermYears: '',
    remainingBalance: '',
    monthlyPayment: 0,
    paymentFrequency: 'Monthly',
    status: 'Active',
    lastPaymentDate: '',
    lastPaymentAmount: '',
    nextPaymentDate: '',
    loanStartDate: '',
    loanEndDate: '',
  });

  useEffect(() => {
    if (loan) {
      setNewLoan(loan);
    } else {
      setNewLoan({
        assetId: '',
        userId: user.id,
        lenderName: '',
        loanAmount: '',
        interestRate: '',
        loanTermYears: '',
        remainingBalance: '',
        monthlyPayment: 0,
        paymentFrequency: 'Monthly',
        status: 'Active',
        lastPaymentDate: '',
        lastPaymentAmount: '',
        nextPaymentDate: '',
        loanStartDate: '',
        loanEndDate: '',
      });
    }
  }, [loan]);

  useEffect(() => {
    if (!open) {
      setShowAdvanced(false);
    }
  }, [open]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewLoan(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const paymentFrequencyOptions = ['Monthly', 'Quarterly', 'Semi-Annually', 'Annually'];
  const statusOptions = ['Active', 'Paid Off', 'Defaulted'];

  return (
    console.log(newLoan),
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{loan ? 'Edit Loan' : 'Add New Loan'}</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            select
            name="assetId"
            label="Select Asset"
            value={newLoan.assetId}
            onChange={handleChange}
            fullWidth
            required
          >
            {user.assets.map(asset => (
              <MenuItem key={asset.assetId} value={asset.assetId}>
                {asset.manufacturer + ' ' + asset.model + ' (' + asset.modelYear + ')'}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            name="lenderName"
            label="Lender Name"
            value={newLoan.lenderName}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            name="loanAmount"
            label="Loan Amount"
            type="number"
            value={newLoan.loanAmount}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            name="interestRate"
            label="Interest Rate (%)"
            type="number"
            value={newLoan.interestRate}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            name="loanTermYears"
            label="Loan Term (Years)"
            type="number"
            value={newLoan.loanTermYears}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            name="remainingBalance"
            label="Remaining Balance"
            type="number"
            value={newLoan.remainingBalance}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            select
            name="paymentFrequency"
            label="Payment Frequency"
            value={newLoan.paymentFrequency}
            onChange={handleChange}
            fullWidth
          >
            {paymentFrequencyOptions.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            name="status"
            label="Status"
            value={newLoan.status}
            onChange={handleChange}
            fullWidth
          >
            {statusOptions.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>

          <Button
            fullWidth
            onClick={() => setShowAdvanced(!showAdvanced)}
            endIcon={<ExpandMoreIcon />}
            sx={{ mt: 2 }}
            color="success"
          >
            Advanced Options
          </Button>

          <Collapse in={showAdvanced}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
              <TextField
                name="lastPaymentDate"
                label="Last Payment Date"
                type="date"
                value={newLoan.lastPaymentDate}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                name="lastPaymentAmount"
                label="Last Payment Amount"
                type="number"
                value={newLoan.lastPaymentAmount}
                onChange={handleChange}
                fullWidth
              />
              <TextField
                name="nextPaymentDate"
                label="Next Payment Date"
                type="date"
                value={newLoan.nextPaymentDate}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                name="startDate"
                label="Start Date"
                type="date"
                value={newLoan.startDate}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                name="endDate"
                label="End Date"
                type="date"
                value={newLoan.endDate}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </Collapse>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="success">Cancel</Button>
        <Button onClick={() => onSave(newLoan)} variant="contained" color="success">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddLoan.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  loan: PropTypes.object
};

export default AddLoan;