import { 
  BACKEND_BASE_URL,
  CREATE_ASSET,
  DELETE_ASSET
} from "../constants/apiConstants";

export async function createAssetService(data) {
  const response = await fetch(BACKEND_BASE_URL + CREATE_ASSET, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
  return await response; 
}

export async function getAssetsService(data) {
  const response = await fetch(BACKEND_BASE_URL + '/GetAssets', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
  return await response.json();
}

export async function deleteAssetService(data) {
  const response = await fetch(BACKEND_BASE_URL + DELETE_ASSET, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
  return await response; 
}