import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { BarChart } from '@mui/x-charts/BarChart';
import { useTheme } from '@mui/material/styles';

const MarketPerformanceBarChart = (data) => {
  const theme = useTheme();
  const colorPalette = [
    theme.palette.success.light,
    theme.palette.success.dark,
  ];



  return (
    <Card variant="outlined" sx={{ width: '100%' }}>
      <CardContent>
        <Typography component="h2" variant="subtitle2" gutterBottom>
          Market Performance Past 6 Months
        </Typography>
        <BarChart
          borderRadius={8}
          colors={colorPalette}
          xAxis={[
            {
              scaleType: 'band',
              categoryGapRatio: 0.5,
              data: data.bookValues.map(entry => entry.date),
            },
          ]}
          series={[
            {
              id: 'book-value',
              label: 'Total Book Value',
              data: data.bookValues.map(entry => entry.valuation),
              stack: 'A',
            },
            {
              id: 'market-value',
              label: 'Estimated Market Value',
              data: data.estimatedMarketValues.map(entry => entry.valuation),
              stack: 'B',
            },
          ]}
          height={313}
          margin={{ left: 65, right: 15, top: 20, bottom: 20 }}
          grid={{ horizontal: true }}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
        />
      </CardContent>
    </Card>
  );
}

export default MarketPerformanceBarChart