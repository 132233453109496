import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Paper,
  Typography
} from '@mui/material';
import { AuthContext } from '../../context/AuthContext';
import LoanTableRow from './LoanTableRow';
import { visuallyHidden } from '@mui/utils';
import Box from '@mui/material/Box';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const LoanTable = ({ onView, onEdit, onDelete }) => {
  const { user } = useContext(AuthContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('loanAmount');

  const loanList = user && user.loans ? user.loans : [];

  const handleRequestSort = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: '70vh' }}>
        <Table stickyHeader className='loan-table' aria-label="loan table" size='small'>
          <TableHead className='white-text-black-background'>
            <TableRow>
            <TableCell className='empty-table-cell'></TableCell>
              <TableCell className='white-text-black-background'>Lender</TableCell>
              <TableCell 
                className='white-text-black-background'
                align="right"
                sortDirection={orderBy === 'loanAmount' ? order : false}
              >
                <TableSortLabel
                  className='white-text-black-background'
                  active={orderBy === 'loanAmount'}
                  direction={orderBy === 'loanAmount' ? order : 'asc'}
                  onClick={handleRequestSort('loanAmount')}
                >
                  {'Loan Amount'}
                  {orderBy === 'loanAmount' ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell 
                className='white-text-black-background'
                align="right"
              >
                <TableSortLabel
                  className='white-text-black-background'
                  active={orderBy === 'interestRate'}
                  direction={orderBy === 'interestRate' ? order : 'asc'}
                  onClick={handleRequestSort('interestRate')}
                >
                  {'Interest Rate'}
                  {orderBy === 'interestRate' ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className='white-text-black-background' align="right">Term (Years)</TableCell>
              <TableCell 
                className='white-text-black-background'
                align="right"
              >
                <TableSortLabel
                  className='white-text-black-background'
                  active={orderBy === 'remainingBalance'}
                  direction={orderBy === 'remainingBalance' ? order : 'asc'}
                  onClick={handleRequestSort('remainingBalance')}
                >
                  {'Remaining Balance'}
                  {orderBy === 'remainingBalance' ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell
                className='white-text-black-background'
                align="right"
              >
                <TableSortLabel
                  className='white-text-black-background'
                  active={orderBy === 'monthlyPayment'}
                  direction={orderBy === 'monthlyPayment' ? order : 'asc'}
                  onClick={handleRequestSort('monthlyPayment')}
                >
                  {'Monthly Payment'}
                  {orderBy === 'monthlyPayment' ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className='white-text-black-background' align="right">Status</TableCell>
              <TableCell className='white-text-black-background' align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loanList.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7} align="right" sx={{ py: 3 }}>
                  <Typography variant="body1" color="text.secondary">
                    No loans available
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              loanList
                .sort(getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((loan) => (
                  <LoanTableRow
                    key={loan.id}
                    loan={loan}
                    onView={onView}
                    onEdit={onEdit}
                    onDelete={onDelete}
                  />
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{
          borderTop: 'none',
          '.MuiTablePagination-toolbar': {
            pb: 0
          }
        }}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={loanList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

LoanTable.propTypes = {
  onView: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default LoanTable;
