import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import formatCurrencyUSD from '../../internals/formatCurrency';

const DashboardTableRow = ({ row }) => {

  row.estimatedDepreciation = row.bookValue - row.fairMarketValue;

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" scope="row">
          {row.manufacturer + ' ' + row.model + ' (' + row.modelYear + ')'}
        </TableCell>
        <TableCell align="right">{formatCurrencyUSD(row.bookValue)}</TableCell>
        <TableCell align="right">{formatCurrencyUSD((row.estimatedDepreciation) / 7)}</TableCell>
        <TableCell align="right">{formatCurrencyUSD(row.fairMarketValue)}</TableCell>
      </TableRow>
    </>
  );
}

export default DashboardTableRow;